
    import Vue from 'vue'
    import {_startcase} from '@/config/util'
    import Archive from '@/assets/svg/ico-archive.svg'
    import ArrowDf from '@/assets/svg/ico-arrow-df.svg'
    import ArrowDown from '@/assets/svg/ico-arrow-down.svg'
    import ArrowLeftAlt from '@/assets/svg/ico-arrow-left-alt.svg'
    import ArrowLeft from '@/assets/svg/ico-arrow-left.svg'
    import ArrowLl from '@/assets/svg/ico-arrow-ll.svg'
    import ArrowRight from '@/assets/svg/ico-arrow-right.svg'
    import ArrowRotate from '@/assets/svg/ico-arrow-rotate.svg'
    import ArrowUp from '@/assets/svg/ico-arrow-up.svg'
    import Arrow from '@/assets/svg/ico-arrow.svg'
    import BanSolid from '@/assets/svg/ico-ban-solid.svg'
    import Badge from '@/assets/svg/ico-badge.svg'
    import Bell from '@/assets/svg/ico-bell.svg'
    import Calendar from '@/assets/svg/ico-calendar.svg'
    import Camera from '@/assets/svg/ico-camera.svg'
    import CheckCircle from '@/assets/svg/ico-check-circle.svg'
    import Check from '@/assets/svg/ico-check.svg'
    import ChevronDown from '@/assets/svg/ico-chevron-down.svg'
    import CloseCircle from '@/assets/svg/ico-close-circle.svg'
    import CloseThin from '@/assets/svg/ico-close-thin.svg'
    import Close from '@/assets/svg/ico-close.svg'
    import Cog from '@/assets/svg/ico-cog.svg'
    import CommCl from '@/assets/svg/ico-comm-cl.svg'
    import Dashboard from '@/assets/svg/ico-dashboard.svg'
    import Desktop from '@/assets/svg/ico-desktop.svg'
    import DotsThree from '@/assets/svg/ico-dots-three.svg'
    import Dots from '@/assets/svg/ico-dots.svg'
    import DotsVertical from '@/assets/svg/ico-dots-vertical.svg'
    import Download from '@/assets/svg/ico-download.svg'
    import Edit from '@/assets/svg/ico-edit.svg'
    import EnvelopeSolid from '@/assets/svg/ico-envelope-solid.svg'
    import Envelope from '@/assets/svg/ico-envelope.svg'
    import Eraser from '@/assets/svg/ico-eraser.svg'
    import Exclamation from '@/assets/svg/ico-exclamation.svg'
    import External from '@/assets/svg/ico-external.svg'
    import Eye from '@/assets/svg/ico-eye.svg'
    import EyeOff from '@/assets/svg/ico-eye-off.svg'
    import Eyeslash from '@/assets/svg/ico-eyeslash.svg'
    import File from '@/assets/svg/ico-file.svg'
    import GoogleDrive from '@/assets/svg/ico-google-drive.svg'
    import Hand from '@/assets/svg/ico-hand.svg'
    import Happy from '@/assets/svg/ico-happy.svg'
    import IconPaperPlane from '@/assets/svg/ico-icon-paper-plane.svg'
    import Info from '@/assets/svg/ico-info.svg'
    import LinesRight from '@/assets/svg/ico-lines-right.svg'
    import Lines from '@/assets/svg/ico-lines.svg'
    import List from '@/assets/svg/ico-list.svg'
    import Location from '@/assets/svg/ico-location.svg'
    import Loop from '@/assets/svg/ico-loop.svg'
    import MTable from '@/assets/svg/ico-m-table.svg'
    import Manage from '@/assets/svg/ico-manage.svg'
    import MessageRounded from '@/assets/svg/ico-message-rounded.svg'
    import Message from '@/assets/svg/ico-message.svg'
    import Minus from '@/assets/svg/ico-minus.svg'
    import Note from '@/assets/svg/ico-note.svg'
    import PaperPlane from '@/assets/svg/ico-paper-plane.svg'
    import Pin from '@/assets/svg/ico-pin.svg'
    import Phone from '@/assets/svg/ico-phone.svg'
    import PlaneCl from '@/assets/svg/ico-plane-cl.svg'
    import Play from '@/assets/svg/ico-play.svg'
    import PlusCircle from '@/assets/svg/ico-plus-circle.svg'
    import Plus from '@/assets/svg/ico-plus.svg'
    import Print from '@/assets/svg/ico-print.svg'
    import Question from '@/assets/svg/ico-question.svg'
    import QuestionThin from '@/assets/svg/ico-question-thin.svg'
    import QuoteLeft from '@/assets/svg/ico-quote-left.svg'
    import QuoteRight from '@/assets/svg/ico-quote-right.svg'
    import ResetPass from '@/assets/svg/ico-reset-pass.svg'
    import SaveRegular from '@/assets/svg/ico-save-regular.svg'
    import Search from '@/assets/svg/ico-search.svg'
    import Settings from '@/assets/svg/ico-settings.svg'
    import SignOut from '@/assets/svg/ico-sign-out.svg'
    import Skip from '@/assets/svg/ico-skip.svg'
    import StarEmpty from '@/assets/svg/ico-star-empty.svg'
    import Star from '@/assets/svg/ico-star.svg'
    import StarLarge from '@/assets/svg/ico-star-large.svg'
    import StarFilled from '@/assets/svg/ico-star-filled.svg'
    import StarSuccess from '@/assets/svg/ico-star-success.svg'
    import SlidersH from '@/assets/svg/ico-sliders-h.svg'
    import Swipe from '@/assets/svg/ico-swipe.svg'
    import IconTable from '@/assets/svg/ico-table.svg'
    import Trash from '@/assets/svg/ico-trash.svg'
    import UserSolid from '@/assets/svg/ico-user-solid.svg'
    import Warning from '@/assets/svg/ico-warning.svg'
    import VideoCamera from '@/assets/svg/ico-video.svg'
    import NoMessages from '@/assets/svg/ico-no-messages.svg'
    import WarningY from '@/assets/svg/ico-warning-y.svg'

    export default Vue.extend({
        components: {
            Archive,
            ArrowDf,
            ArrowDown,
            ArrowLeftAlt,
            ArrowLeft,
            ArrowLl,
            ArrowRight,
            ArrowRotate,
            ArrowUp,
            Arrow,
            BanSolid,
            Badge,
            Bell,
            Calendar,
            Camera,
            CheckCircle,
            Check,
            ChevronDown,
            CloseCircle,
            CloseThin,
            Close,
            Cog,
            CommCl,
            Dashboard,
            Desktop,
            DotsThree,
            DotsVertical,
            Dots,
            Download,
            Edit,
            EnvelopeSolid,
            Envelope,
            Eraser,
            Exclamation,
            External,
            Eye,
            EyeOff,
            Eyeslash,
            File,
            GoogleDrive,
            Hand,
            Happy,
            IconPaperPlane,
            Info,
            LinesRight,
            Lines,
            List,
            Location,
            Loop,
            MTable,
            Manage,
            MessageRounded,
            Message,
            Minus,
            Note,
            PaperPlane,
            Pin,
            Phone,
            PlaneCl,
            Play,
            PlusCircle,
            Plus,
            Print,
            Question,
            QuestionThin,
            QuoteLeft,
            QuoteRight,
            ResetPass,
            SaveRegular,
            Search,
            Settings,
            SignOut,
            Skip,
            SlidersH,
            Star,
            StarLarge,
            StarFilled,
            StarEmpty,
            StarSuccess,
            Swipe,
            IconTable,
            Trash,
            UserSolid,
            Warning,
            VideoCamera,
            NoMessages,
            WarningY,
        },
        props: {
            iconName: {
                type: String,
                required: true,
            },
        },
        computed: {
            component() {
                return _startcase(this.iconName.replace('ico-', '')).replace(' ', '')
            },
        },
    })
